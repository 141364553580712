import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Subscriptions from './containers/Subscriptions/Subscriptions';
import { Routes, Route } from "react-router-dom";
import Consultancy from './containers/Consultancy/Consultancy';
import About from './containers/About/About';
import Home from './containers/Home/Home';
import { ContextProvider } from './context/AppContext';

function App() {

  return (
    <div className="App">
      <ContextProvider>
        <Header/>
        <Routes>
          <Route path='/suscripciones' element={<Subscriptions/>}/>
          <Route path='/consultoria' element={<Consultancy/>}/>
          <Route path='/nosotros' element={<About/>}/>
          <Route path='/' element={<Home/>}/>
        </Routes>
        <Footer/>
      </ContextProvider>
    </div>
  );
}

export default App;
