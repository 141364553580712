import clean from "../../assets/clean.png"
import emisions from "../../assets/emisions.png"
import instruments from "../../assets/instruments.png"
import politics from "../../assets/politics.png"
import h2 from "../../assets/h2.png"
import assets from "../../assets/assets.png"
import regular from "../../assets/regular.png"
import planning from "../../assets/planning.png"
import system from "../../assets/system.png"
import prices from "../../assets/prices.png"
import renew from "../../assets/renew.png"
import site from "../../assets/site.png"
import generation from "../../assets/generation.png"
import storage from "../../assets/storage.png"
import ppa from "../../assets/ppa.png"
import energy from "../../assets/energy.png"
import commerce from "../../assets/commerce.png"
import consuloria from "../../assets/consultoria.png";
import advisory from "../../assets/ad.png";

export const consultContent = {
  title:{
    eng: "Advisory",
    esp: "Consultoría"
  },
  headerImg: {
    eng:advisory,
    esp:consuloria
  },
  containers: [
    {
      title:{
        eng: "Climate and renewables",
        esp: "Ambientales y Renovables"
      },
      cards:[
        {
          title:{
            eng: "Clean Tech",
            esp: "Tecnología limpia"
          },
          text:{
            eng: "We identify the best arrangement to supply wind or solar power to your project.",
            esp: "Identificamos el mejor arreglo para suministrar tu proyecto con energía eólica o solar."
          },
          image: clean
        },
        {
          title:{
            eng: "Emissions analysis",
            esp: "Análisis de emisiones"
          },
          text:{
            eng: "We measure and help you reduce emissions.",
            esp: "Medimos y ayudamos a reducir tus emisiones a la atmósfera."
          },
          image: emisions
        },
        {
          title:{
            eng: "Carbon allowances and clean energy credits",
            esp: "Instrumentos financieros y certificados de energía límpia"
          },
          text:{
            eng: "Let’s find funding and evaluate financial alternatives to make your project a reality.",
            esp: "Te apoyamos a encontrar fuentes de financiamiento y realizamos la evaluación financiera de instrumentos que hagan realidad tu proyecto."
          },
          image: instruments
        },
        {
          title:{
            eng: "Policy analysis",
            esp: "Análisis de políticas y procedimientos"
          },
          text:{
            eng: "Lets develop together internal policies and procedures to enhance your company.",
            esp: "Desarrollamos contigo documentos internos para potenciar tu empresa."
          },
          image: politics
        },
        {
          title:{
            eng: "Hydrogen",
            esp: "Hidrógeno"
          },
          text:{
            eng: "Blue and green hydrogen projects.",
            esp: "Diseñamos proyectos con la última tecnología de hidrógeno verde y azul."
          },
          image: h2
        }
      ]
    },
    {
      title:{
        eng: "Electricity and fuels",
        esp: "Electricidad y Combustibles"
      },
      cards:[
        {
          title:{
            eng: "Asset valuations and M&A Advisory",
            esp: "Evaluación de activos, fusiones y adquisiciones"
          },
          text:{
            eng: "Brownfields and Greenfields, we assist you on all stages to sell or buy.",
            esp: "Analizamos el valor de proyectos en desarrollo y operación."
          },
          image: assets
        },
        {
          title:{
            eng: "Regulatory and public policy analysis",
            esp: "Análisis regulatorio y de políticas públicas"
          },
          text:{
            eng: "We provide insight, strategy, and regulatory risk management.",
            esp: "Evaluamos la política pública, regulación y su impacto sobre tu negocio."
          },
          image: regular
        },
        {
          title:{
            eng: "Infrastructure planning",
            esp: "Planeación de Infraestructura y proyectos"
          },
          text:{
            eng: "Strategic and project management.",
            esp: `Administración de proyectos y control estratégico.`
          },
          image: planning
        },
        {
          title:{
            eng: "System operations",
            esp: "Sistemas operativos"
          },
          text:{
            eng: "We design <i><b>software</b></i> and assist you to select hardware and procedures to operate your projects.",
            esp: "Diseñamos <i><b>software</b></i>, te ayudamos a seleccionar hardware y procedimientos para operar tus proyectos."
          },
          image: system
        },
        {
          title:{
            eng: "Market price forecast",
            esp: "Pronósticos de precios de mercado"
          },
          text:{
            eng: "Modeling natural gas and power markets with high granularity and fidelity.",
            esp: "Estimamos el valor de los productos y servicios energéticos para tu proyecto."
          },
          image: prices
        },
        {
          title:{
            eng: "Renewable integration, congestion, and curtailment",
            esp: "Integración renovable, congestión y restricción"
          },
          text:{
            eng: "We help you solve <i><b>curtailment</b></i> problems in your site, and other operational challengesyou’re your company.",
            esp: "Solucionamos problemas de <i><b>curtailment</b></i> en tus nodos y retos operativos para tu empresa."
          },
          image: renew
        }
      ]
    },
    {
      title:{
        eng: "End user solutions",
        esp: "Soluciones a usuarios finales"
      },
      cards:[
        {
          title:{
            eng: "On site solutions",
            esp: "Soluciones en sitio"
          },
          text:{
            eng: "Let’s put together ideas to enhance your energy efficiency, interconnection challenges and more.",
            esp: "Implementamos ideas para mejorar tu eficiencia energética."
          },
          image: site
        },
        {
          title:{
            eng: "Distributed generation",
            esp: "Generación distribuida"
          },
          text:{
            eng: "We can develop projects complying with Mexican rules and optimizing return.",
            esp: "Desarrollamos los proyectos ideales conforme a las reglas de los mercados en México."
          },
          image: generation
        },
        {
          title:{
            eng: "Storage solutions",
            esp: "Soluciones de almacenamiento"
          },
          text:{
            eng: "Our team will find the bests arrangement to minimize your energy expenses.",
            esp: "Encontramos la forma de optimizar proyectos intermitentes para reducir tus costos de energía."
          },
          image: storage
        },
        {
          title:{
            eng: "PPA",
            esp: "Contratos de compraventa de energia (PPA)"
          },
          text:{
            eng: "We assist you to negotiate the best purchase agreements for your business, reviewing both prices and conditions.",
            esp: "Desarrollamos los contratos de compraventa adecuados para que tu negocio cuente con la energía que requiere, en los mejores precios y condiciones disponibles."
          },
          image: ppa
        },
        {
          title:{
            eng: "Energy Procurement",
            esp: "Procura de energía (Electricidad y gas)"
          },
          text:{
            eng: "Request for proposals to obtain quotations from the best <i><b>traders</b></i> in the market.",
            esp: "Elaboramos concursos y procesos de contratación de energía para suministrar tus puntos de consumo con los mejores <i><b>traders</b></i> del mercado."
          },
          image: energy
        },
        {
          title:{
            eng: "Commercial and risk analysis",
            esp: "Análisis comerciales y de riesgos"
          },
          text:{
            eng: "We assist our customers to understand the market as well as its rules and challenges.",
            esp: "Ayudamos a nuestros clientes a conocer el mercado y sus riesgos."
          },
          image: commerce
        }
      ]
    }
  ]
}