import { useEffect, useRef } from "react";
import CarouselItem from "../CarouselItem/CarouselItem";
import "./CarouselContainer.css"
import { homeContent } from "../../containers/Home/content";

const CarouselContainer = () => {
  const carouselRef = useRef(null)

  const transitionCard = () => {
    const firstElement = carouselRef.current.children[0]
    carouselRef.current.style.transition = "none";
    carouselRef.current.style.transform = `translateX(0)`;
    carouselRef.current.appendChild(firstElement);
    carouselRef.current.removeEventListener("transitionend", transitionCard)
  }

  useEffect(() => {
    if(carouselRef.current.children.length > 0){
      setInterval(() => {
        nextCard()
      },10000)
    }
  },[])

  const nextCard = () => {
    if(carouselRef.current.children.length > 0){
        
      carouselRef.current.style.transition = "1000ms ease-out all"
      const cardLength = carouselRef.current.children[0].offsetWidth;
      carouselRef.current.style.transform = `translateX(-${cardLength}px)`;
      carouselRef.current.addEventListener("transitionend",transitionCard)

    }
  }

  const prevCard = () => {
    if(carouselRef.current.children.length > 0){
        
      const index = carouselRef.current.children.length - 1
      const lastElement = carouselRef.current.children[index]
      carouselRef.current.insertBefore(lastElement,carouselRef.current.firstChild)
      carouselRef.current.style.transition = "none";
      const cardLength = carouselRef.current.children[0].offsetWidth;
      carouselRef.current.style.transform = `translateX(-${cardLength}px)`

      setTimeout(() => {
        carouselRef.current.style.transition = "1000ms ease-out all"
        carouselRef.current.style.transform = `translateX(0)`
      },100)
     
    }
  }
  return(
    <>
      <span 
        className="carousel-button b-left"
        onClick={() => {
          prevCard()
        }}
      ></span>
      <div className="carousel-container" ref={carouselRef}>
       {
        homeContent.carousel &&
        homeContent.carousel.map((item,i)=> 
          <CarouselItem 
            title={item.title} 
            image={item.image} 
            text={item.text}
            url={item.url}
            key={i}
          />
        )
       }
      </div>
      <span 
        className="carousel-button b-right"
        onClick={() => {
          nextCard()
        }}
      ></span>
    </>
  )
}

export default CarouselContainer;