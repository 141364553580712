import clients from "../../assets/clients.png"
import agil from "../../assets/agil.png"
import practice from "../../assets/practice.png"
import ambient from "../../assets/ambient.png"
import know from "../../assets/know.png"

export const aboutContent = {
  title:{
    eng: "Who we are",
    esp: "Quiénes somos"
  },
  cards:[
    {
      title:{
        eng: "CUSTOMER FIRST",
        esp: "EL CLIENTE PRIMERO"
      },
      text:{
        eng: "We are in business because of our customers. We take the perspective of our client.",
        esp: "Trabajamos por nuestros clientes, tomamos su perspectiva."
      },
      image: clients,
      orientation: "left",
      background: "#FFFFFF"
    },
    {
      title:{
        eng: "LEAN & AGILE",
        esp: "APOYO ÁGIL"
      },
      text:{
        eng: "Continuously creating value and eliminating waste.",
        esp: "Creamos valor constantemente y optimizamos recursos."
      },
      image: agil,
      orientation: "right",
      background: "#70A7A3"
    },
    {
      title:{
        eng: "BEST PRACTICES",
        esp: "MEJORES PRÁCTICAS"
      },
      text:{
        eng: "Outstanding organizational, project management, methodological practices.",
        esp: "Modelamos y gestionamos proyectos aplicando procesos, metodologías y herramientas ágiles y escalables, probadas internacionalmente en diversos mercados energéticos del mundo."
      },
      image: practice,
      orientation: "left",
      background: "#FFFFFF"
    },
    {
      title:{
        eng: "ENVIRONMENT OF EXCELLENCE",
        esp: "AMBIENTE DE EXCELENCIA"
      },
      text:{
        eng: "Consistently deliver beyond agreed standards and produce outstanding results.",
        esp: "Entregamos valor consistentemente, más allá de los estándares acordados y producimos resultados sobresalientes."
      },
      image: ambient,
      orientation: "right",
      background: "#E9A45D"
    },
    {
      title:{
        eng: "KNOWLEDGE",
        esp: "CONOCIMIENTO"
      },
      text:{
        eng: "Our depth of understanding and experience underpins all that we do.",
        esp: "Nuestra profundidad de comprensión y experiencia sustenta todo lo que hacemos."
      },
      image: know,
      orientation: "left",
      background: "#FFFFFF"
    },
  ],
  mision:{
    title:{
      eng: "MISSION CORE VALUES",
      esp: "MISIÓN Y VALORES"
    },
    text:{
      eng: "Our mission is to provide innovative, enduring, and useful work products in the Mexican energy space.",
      esp: "Nuestra misión es proporcionar productos innovadores, duraderos y útiles para el sector energético mexicano."
    },
    text2:{
      eng: "We listen to our customers’ needs, focus on the user experience, and solve problems with elegant simplicity.",
      esp: "Escuchamos las necesidades de nuestros clientes, nos enfocamos en la experiencia del usuario y resolvemos problemas con elegante simplicidad."
    }
  }
}