import ImagesCarousel from "../../components/ImagesCarousel/ImagesCarousel"
import SubscriptionCard from "../../components/SubscriptionCard/SubscriptionCard"
import "./Subscriptions.css"
import { subscriptionContent } from "./content"
import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

const Subscriptions = () => {
  const {language} = useContext(AppContext)
  return(
    <>
      <ImagesCarousel/>
      <div className="subscriptions-container">
        <h1 className="subscriptions-title">
          {subscriptionContent.title[language]}
        </h1>
        <div className="subscriptions-info-container">
          <div className="subscriptions-cards-container">
            {
              subscriptionContent &&
              subscriptionContent.cards.map((item,i) => 
                <SubscriptionCard
                  title={item.title[language]}
                  subtitle={item.subtitle[language]}
                  text={item.text[language]}
                  button={item.button[language]}
                  key={i}
                />  
              )
            }
          </div>
          <div className="subscriptions-products-details">
            <p className="products-details-title">
              {subscriptionContent.subscriptions.title[language]}
            </p>
            <ul className="products-details-list">
              {
                subscriptionContent.subscriptions.list.map((item,i) =>
                  <li className="details-list-item" key={i}>
                    {item[language]}
                  </li>
                )
              }
            </ul>
          </div>
        </div> 
      </div>
    </>
  )
}

export default Subscriptions