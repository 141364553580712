import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import ConsultancyCard from "../ConsultancyCards/ConsultancyCard";
import "./ConsultancyCardsContainer.css";

const ConsultancyCardsContainer = ({title,cards}) => {
  const {language} = useContext(AppContext)
  return(
    <>
      <div className="c-cards-container">
        <h2 className="c-cards-title">
          {title}
        </h2>
        <div className="divider-container">
          <div className="divider"></div>
        </div>
        <div className="c-cards">
          {
            cards &&
            cards.map((item,i) => 
              <ConsultancyCard
                title={item.title[language]}
                text={item.text[language]}
                image={item.image}
                key={i}
              />  
            )
          }
        </div>
      </div>
    </>
  )
}

export default ConsultancyCardsContainer;