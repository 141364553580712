import "./AboutCard.css"

const AboutCard = ({orientation,title,text,image,background}) => {
  return(
    <>
      <div className={`about-card-container ${orientation === "right" && "right"}`}> 
        <div className={`about-image-container ${orientation === "right" && "right-image"}`}> 
            <img src={image} alt="card"/>
        </div>
        <div className={`about-card-info ${orientation === "right" && "right-info"}`}>
          <div className="about-card-title"> 
            <h1>
              {title}  
            </h1> 
          </div>
          <div className="about-card-content" style={{"backgroundColor" : `${background}`}}>
            <p> 
              {text}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutCard;