import { useState } from "react";
import FormModal from "../FormModal/FormModal";
import "./Footer.css"
import { footerContent } from "./content";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const Footer = () => {
  const [isClosed, setIsClosed] = useState(true);
  const {language} = useContext(AppContext)
  return(
    <>
      {
        !isClosed && <FormModal isClosed={isClosed} setIsClosed={setIsClosed}/>
      }
      <footer className="footer">
          <div className="contact-badge" onClick={() => setIsClosed(!isClosed)}>
            {footerContent.contact[language]}
          </div>
          <div className="footer-container">
            <a href="mailto:marketintel@elevationideas.mx ">
              <p className="footer-container-text">
                marketintel@elevationideas.mx 
              </p>
            </a>
            <a href="tel:+525528481031">
              <p className="footer-container-text">
                Tel: +52 55 2848 1031
              </p>
            </a>
          </div>
      </footer>
    </>
  )
}

export default Footer;