import CarouselContainer from "../../components/CarouselContainer/CarouselContainer";
import "./Home.css";

const Home = () => {
  return(
  <>
    <div className="home-container">
      <CarouselContainer/>
    </div>
  </>
  )
}

export default Home;