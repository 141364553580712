export const headerContent = {
  home: {
    eng: "HOME",
    esp: "INICIO"
  },
  about: {
    eng: "WHO WE ARE",
    esp: "QUIÉNES SOMOS"
  },
  consultancy: {
    eng: "ADVISORY",
    esp: "CONSULTORÍA"
  },
  subscriptions: {
    eng: "SUBSCRIPTIONS",
    esp: "SUSCRIPCIONES"
  }
}