import { useState } from "react";
import FormModal from "../FormModal/FormModal";
import "./SubscriptionCard.css"

const SubscriptionCard = ({title, subtitle, text, button}) => {
  const [isClosed, setIsClosed] = useState(true);
  return(
    <>
      {
        !isClosed && <FormModal isClosed={isClosed} setIsClosed={setIsClosed}/>
      }
      <div className="subscription-card-container">
          <div>
            <div className="subscription-card-title">
              <h1 dangerouslySetInnerHTML={{__html:title}}/>
            </div>
            <div className="subscription-card-content">
              <p className="subscription-card-subtitle"> 
                {subtitle}
              </p>
              <p className="subscription-card-text" dangerouslySetInnerHTML={{__html:text}}/> 
            </div>
          </div>
          <div className="subscription-card-button" onClick={() => setIsClosed(!isClosed)}> 
            <p> 
              {button}
            </p>
          </div>
      </div>
    </>
  )
}

export default SubscriptionCard