import "./CarouselItem.css"
import {useNavigate} from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const CarouselItem = ({image, title, text, url}) => {
  const {language} = useContext(AppContext)
  const history = useNavigate()
  return(
    <>
      <div className="carousel-item-container">
        <div className="carousel-item" onClick={() => history(url)}>
          <div className="carousel-image-container">
            <img 
              className="carousel-image"
              src={image} 
              alt="image"
            />
          </div>
          <div className="carousel-info-container">
            {
              title.image ? 
                <img 
                  className="carousel-info-title" 
                  src={title.image} 
                  alt="title" 
                />
              :
                <p className="carousel-title-text">
                  {title[language]}
                </p>
            }
            <p className="carousel-info-text" dangerouslySetInnerHTML={{__html:text[language]}}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default CarouselItem;